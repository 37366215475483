<template>
	<div class="wrapper" v-show="viewShow">
		<!--证书查询 -->
		<section class="wrapper_top"><img :src="require('@/assets/image/fh.png')" alt="" @click="fh()" /></section>
		<section class="wrapper_centent">
			<div class="logo"><img :src="require('@/assets/image/logo2.png')" alt="" /></div>

			<div class="titleValue">職業普通話水平測試</div>

			<div class="box">
				<div class="uls">
					<div class="ulsTitleValue">{{ obj.level }}</div>
					<div class="ulsName">{{ obj.testName }}</div>
					<div class="ulsName2">{{ obj.testNamePinyin }}</div>
					<ul>
						<li class="lis_hr">
							<div class="listLeft">分數</div>
							<div class="listRight">{{ obj.total }}</div>
						</li>
						<li class="lis_hr">
							<div class="listLeft">等級</div>
							<div class="listRight">{{ obj.level }}</div>
						</li>
						<li class="lis_hr">
							<div class="listLeft">考生號</div>
							<div class="listRight">{{ obj.testCode }}</div>
						</li>
						<li class="lis_hr" v-if="obj.level != '不入等級'">
							<div class="listLeft">證書編號</div>
							<div class="listRight">{{obj.certificateNo}}</div>
						</li>
						<li class="lis_hr" v-if="obj.level != '不入等級'">
							<div class="listLeft">發證日期</div>
							<div class="listRight">{{obj.certificateDate}}</div>
						</li>
						<li v-if="false">
							<div class="listLeft">證書一覽</div>
							<div class="listRight" @click="ck(obj.certificateUrl)">
								<!-- @click="ck" -->
								<!-- <a :href="obj.certificateUrl" target="_blank" style="display: flex;align-items: center;"> -->
								<span>點擊查看</span>
								<img :src="require('@/assets/image/you.png')" alt="" />
								<!-- </a> -->
							</div>
						</li>
					</ul>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	components: {},
	name: 'certificate',
	data() {
		return {
			obj: null
		};
	},
	watch: {},
	components: {},
	created() {},
	mounted() { 
		this.obj = JSON.parse(window.sessionStorage.getItem('QuerySore'));
	},
	computed: {
		...mapState(['isPc', 'viewShow']),
		viewShow() {
			return this.$store.state.viewShow;
		}
	},
	methods: {
		fh() {
			history.go(-1); //返回首页
		},
		ck(e) {
			// this.$router.push('/achievement');
			this.$router.push({ path: '/achievement?url='+JSON.stringify(e)}) 
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/index.scss';

.wrapper {
	width: 100%;
	height: 100%;
	position: relative;
	// font-size: px2rem(28);
	overflow-x: hidden;
	// background: linear-gradient(309deg, #132bb0 0%, #1934cb 100%);
	background: url('~@/assets/image/cjibg.png') no-repeat;
	background-size: 100%;
	padding: px2rem(60) px2rem(32);
	box-sizing: border-box;

	.wrapper_top {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: px2rem(80);
		text-align: center;
		line-height: px2rem(80);
		font-size: px2rem(36);
		// background: #ffffff;
		font-family: PingFang SC-Semibold, PingFang SC;
		font-weight: 600;
		color: #000000;

		img {
			width: px2rem(56);
			height: px2rem(56);
			position: absolute;
			left: px2rem(32);
			top: 0;
			bottom: 0;
			margin: auto;
		}
	}

	.wrapper_centent {
		width: 100%;
		padding: px2rem(32);
		padding-left: px2rem(66);
		padding-right: px2rem(66);
		box-sizing: border-box;

		.logo {
			width: px2rem(128);
			height: px2rem(128);
			margin: auto;
			display: flex;
			align-items: center;
			justify-content: center;
			background: #ffffff;
			box-shadow: 0px px2rem(4) px2rem(40) 0px rgba(0, 0, 0, 0.12);
			border-radius: px2rem(32);

			img {
				width: px2rem(76.8);
				height: px2rem(96);
			}
		}

		.titleValue {
			text-align: center;
			font-size: px2rem(48);
			font-family: PingFang SC-Semibold, PingFang SC;
			font-weight: 600;
			color: #ffffff;
			line-height: px2rem(56);
			margin-top: px2rem(32);
		}

		.box {
			width: 100%;
			// min-height: px2rem(840);
			margin-top: px2rem(84);
			background: #ffffff;
			box-shadow: px2rem(-2) px2rem(8) px2rem(40) 0px rgba(32, 71, 208, 0.15);
			border-radius: px2rem(8);
			padding: px2rem(16);
			box-sizing: border-box;
			position: relative;

			.uls {
				width: 100%;
				height: 100%;
				padding: px2rem(48) px2rem(48) 0 px2rem(48);
				box-sizing: border-box;
				background: #ffffff;
				border-radius: px2rem(4);
				border: px2rem(3) solid #2147cf;

				.ulsTitleValue {
					text-align: center;
					font-size: px2rem(48);
					font-family: PingFang SC-Semibold, PingFang SC;
					font-weight: 600;
					color: #e80202;
					line-height: px2rem(56);
				}

				.ulsName {
					text-align: center;
					margin-top: px2rem(20);
					font-size: px2rem(32);
					font-family: PingFang SC-Semibold, PingFang SC;
					font-weight: 600;
					color: #333333;
					line-height: px2rem(38);
				}
				.ulsName2 {
					text-align: center;
					margin-top: px2rem(20);
					font-size: px2rem(28);
					font-family: PingFang SC-Semibold, PingFang SC;
					font-weight: 600;
					color: #999999;
					line-height: px2rem(38);
				}

				ul {
					width: 100%;

					.lis_hr {
						border-bottom: px2rem(2) solid #f2f2f2;
					}

					li {
						width: 100%;
						height: px2rem(104);
						display: flex;
						justify-content: space-between;
						align-items: center;

						.listLeft {
							font-size: px2rem(28);
							font-family: PingFang SC-Regular, PingFang SC;
							font-weight: 400;
							color: #999999;
							line-height: px2rem(33);
							text-align: left;
						}

						.listRight {
							font-size: px2rem(28);
							font-family: PingFang SC-Regular, PingFang SC;
							font-weight: 400;
							color: #333333;
							line-height: px2rem(33);
							text-align: right;
							display: flex;
							align-items: center;

							span {
								color: #122ab0;
								margin-right: px2rem(8);
							}
							img {
								width: px2rem(24);
								height: px2rem(24);
							}
						}
					}
				}
			}
		}

		.box:after {
			content: '';
			width: 90%;
			height: px2rem(16);
			background: #ffe600;
			position: absolute;
			left: 0;
			right: 0;
			bottom: px2rem(-16);
			margin: auto;
			background: #ffffff;
			box-shadow: px2rem(-2) px2rem(8) px2rem(40) 0px rgba(32, 71, 208, 0.15);
			border-radius: 0 0 px2rem(8) px2rem(8);
		}
	}
}
</style>
